export const createLabel = (label = '', hasLabel = true) => ({
    hasLabel,
    label
});

export const createPlaceholder = (placeholder = '', hasPlaceholder = false) => ({
    hasPlaceholder,
    placeholder,
});

export const createTooltip = (infoTooltipText = '', hasInfoTooltip = false) => ({
    hasInfoTooltip,
    infoTooltipText
});

export const createDefaultValue = (defaultValue = '', hasDefaultValue = false, persistDefaultValue = false) => ({
    hasDefaultValue,
    defaultValue,
    persistDefaultValue
});

export const createLimit = (limit = 50, hasLimit = false) => ({
    hasLimit,
    limit
});

export const createDynamicChoices = (dynamicChoices = '', choices = ['Choice #1', 'Choice #2'], hasDynamicChoices = false) => ({
    hasDynamicChoices,
    dynamicChoices,
    choices
});

export const createText = (rawText = '<p>My text...</p>', text = '') => ({
    text,
    rawText
});

export const createIgnoreHiddenFieldValues = () => ({
    ignoreHiddenFieldValue: false,
    isIgnoreHiddenFieldValueDynamic: false
});

export const createAddressSubFieldsBasicProps = () => ({
    street: {},
    number: {},
    zipCode: {
        maxLength: ''
    },
    city: {},
    country: {},
    state: {},
    district: {}
});
