<template>
  <div>
    <ScreenOptions 
      v-model="formBuilderInfo"
    />
    <div>
      <BaseSelect
        v-model="formBuilderInfo.additionalInfo.authModule"
        label="Auth Module"
        class="has-text-left"
        @input="accountRecoveryMapper.selectedAuthModule($event)"
      >
        <option
          class="has-text-left"
          :value="null"
        >
          -- Not Selected --
        </option>
        <option
          v-for="(authModule, idx) in authModules"
          :key="idx+'-'+authModule.id"
          :value="authModule.id"
          class="has-text-left"
        >
          {{ authModule.name }}
        </option>
      </BaseSelect>
    </div>
    <BaseSelect
      v-model="formBuilderInfo.additionalInfo.resetPasswordModule"
      label="Reset Password Module"
      class="has-text-left"
      @input="accountRecoveryMapper.selectedResetPasswordModule($event)"
    >
      <option
        class="has-text-left"
        :value="null"
      >
        -- Not Selected --
      </option>
      <option
        v-for="(resetPasswordModule, idx) in resetPasswordModules"
        :key="idx+'-'+resetPasswordModule.id"
        :value="resetPasswordModule.id"
        class="has-text-left"
      >
        {{ resetPasswordModule.name }}
      </option>
    </BaseSelect>
    <h1>Account Recovery</h1>
    <BaseSelect
      label="Primary Id Column Type"
      :value="formBuilderInfo.additionalInfo.primaryIdColumnType || ''"
      @input="accountRecoveryMapper.swapPrimaryIdColumnType($event, 'primaryIdColumnType')"
    >
      <option
        v-for="(idColumn, index) in primaryIdColumnTypes"
        :key="index+'-'+idColumn.id"
        :value="idColumn.id"
      >
        {{ idColumn.value }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      v-model="formBuilderInfo.additionalInfo.primaryIdColumn"
      label="Primary Id Column"
    >
      <option
        v-for="(column, columnIdx) in databaseOptions.columns"
        :key="'column-'+columnIdx"
        :value="column.column_name"
      >
        {{ column.column_label }}
      </option>
    </BaseSelect>

    <BaseSelect
      v-model="formBuilderInfo.additionalInfo.passwordColumn"
      disabled
      label="Password Column"
    >
      <option
        v-for="(column, columnIdx) in databaseOptions.columns"
        :key="'column-'+columnIdx"
        :value="column.column_name"
      >
        {{ column.column_label }}
      </option>
    </BaseSelect>

    <Editor
      v-model="formBuilderInfo.additionalInfo.sender"
      :input="true"
      :formula="true"
      label="Sender"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="formBuilderInfo.additionalInfo.subject"
      :input="true"
      :formula="true"
      label="Subject"
      style="margin-bottom: 20px"
    />

    <Editor
      v-model="formBuilderInfo.additionalInfo.emailBody"
      :input="false"
      label="Content"
      style="margin-bottom: 20px"
    />

    <b-button
      type="is-primary"
      expanded
      @click="isFormBuilderModalActive = true;"
    >
      <b-icon
        class="mr-1"
        icon="pencil-box-multiple-outline"
      />
      Edit Form
    </b-button>
    <FormBuilderModal 
      v-model="isFormBuilderModalActive"
    />
  </div>
</template>
  
<script >
// libs
import { ref, onMounted, watch } from '@vue/composition-api';

// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import FormBuilderModal from '@/modules/builder/components/form-builder/FormBuilderModal.vue';
import ScreenOptions from '@/modules/builder/components/module-sidebar/screen/shared/ScreenOptions.vue';

// services
import { fetchLayoutService } from '@/services/application-service/layoutRequests';
import { fetchTablesService } from '@/services/database-service/tableRequests';
import { getColumnsService } from '@/services/database-service/columnRequests';
import { getAuthModuleListService } from '@/services/application-service/module-auth/authModuleRequests';
import { fetchModuleByIdService } from '@/services/application-service/moduleRequests';
import { fetchModuleNodeService } from '@/services/application-service/moduleNodesResquests';

// composables
import { useRoute } from '@/hooks/vueRouter';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useVariableManager } from '@/modules/builder/components/module-sidebar/screen/shared/screenVariables';

// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';

// others
import { createSection, createTextInput, createEmailInput, createPasswordInput, createNextButton } from '@/modules/builder/components/form-builder/form-elements/elementFactories';
import { generateDefaultForm } from '@/modules/builder/components/module-sidebar/screen/shared/screenUtils';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const props = __props;
  const emit = __ctx.emit;

  // composables specific to this component
  const useAccountRecoveryMapper = () => {
    const accountRecoveryMapper = {};
    accountRecoveryMapper.databaseOptions = ref({
      databases: [],
      tables: [],
      columns: []
    });
    accountRecoveryMapper.updateSelectedDatabase = databaseId => {
      const matchedDatabase = databaseOptions.value.databases.find(db => db.id === databaseId);
      if (matchedDatabase) {
        formBuilderInfo.value.additionalInfo.database = matchedDatabase.id;
        formBuilderInfo.value.additionalInfo.table = '';
        formBuilderInfo.value.additionalInfo.idColumn = '';
        formBuilderInfo.value.additionalInfo.passwordColumn = '';
        loadTables();
      }
    };
    accountRecoveryMapper.updateSelectedTable = tableName => {
      const matchedTable = databaseOptions.value.tables.find(table => table.TABLE_NAME === tableName);
      if (matchedTable) {
        formBuilderInfo.value.additionalInfo.table = matchedTable.TABLE_NAME;
        formBuilderInfo.value.additionalInfo.idColumn = '';
        formBuilderInfo.value.additionalInfo.passwordColumn = '';
        loadColumns();
      }
    };
    accountRecoveryMapper.swapPrimaryIdColumnType = (idColumnTypeValue, idColumnTypeName) => {
      const oldType = formBuilderInfo.value.additionalInfo[idColumnTypeName];
      formBuilderInfo.value.additionalInfo[idColumnTypeName] = idColumnTypeValue;
      formBuilderInfo.value.additionalInfo.idColumnType = idColumnTypeValue;
      const newField = idColumnTypeValue === 'email-input' ? createEmailInput() : createTextInput();
      replaceField(oldType, newField);
    };
    const replaceField = (existingFieldType, newField) => {
      formBuilderInfo.value.sections.forEach(section => {
        section.fields.forEach(row => {
          row.forEach((field, index) => {
            if (field.type === existingFieldType) {
              newField.id = field.id; // conserve existing field id
              row[index] = newField;
            }
          });
        });
      });
    };
    const loadTables = async () => {
      try {
        if (formBuilderInfo.value.additionalInfo.database) {
          const {
            data: {
              data
            }
          } = await fetchTablesService(formBuilderInfo.value.additionalInfo.database);
          databaseOptions.value.tables = data;
        }
      } catch (err) {
        console.error(err);
      }
    };
    const loadColumns = async () => {
      try {
        if (formBuilderInfo.value.additionalInfo.database && formBuilderInfo.value.additionalInfo.table) {
          const {
            data: {
              data
            }
          } = await getColumnsService(formBuilderInfo.value.additionalInfo.database, formBuilderInfo.value.additionalInfo.table);
          databaseOptions.value.columns = data;
        }
      } catch (err) {
        console.error(err);
      }
    };
    accountRecoveryMapper.selectedAuthModule = async authModuleId => {
      // fetch auth module additionInfo and set those info in account recovery module
      const {
        data: {
          data: authModuleData
        }
      } = await fetchModuleByIdService(route.params.appId, authModuleId);
      const authNode = authModuleData?.auth_nodes?.nodes.find(v => v.type === 'Sign In');
      const {
        data: {
          data: {
            data: nodeData
          }
        }
      } = await fetchModuleNodeService(route.params.appId, authModuleId, authNode.nodeId);
      formBuilderInfo.value.additionalInfo.database = nodeData.additionalInfo.database;
      formBuilderInfo.value.additionalInfo.table = nodeData.additionalInfo.table;
      formBuilderInfo.value.additionalInfo.passwordColumn = nodeData.additionalInfo.passwordColumn;
      await loadTables();
      await loadColumns();
    };
    accountRecoveryMapper.selectedResetPasswordModule = async resetPasswordModule => {
      try {
        formBuilderInfo.value.additionalInfo.resetPasswordModule = resetPasswordModule;
      } catch (err) {
        console.log(err);
      }
    };
    const fetchAuthModules = async () => {
      const authModule = await getAuthModuleListService(route.params.appId);
      authModules.value = authModule.data.data.filter(v => v.auth_nodes?.nodes.some(e => e.type === 'Sign In'));
      resetPasswordModules.value = authModule.data.data.filter(v => v.auth_nodes?.nodes.some(e => e.type === 'Reset Password'));
    };
    onMounted(() => {
      fetchAuthModules();
      loadTables();
      loadColumns();
    });
    return accountRecoveryMapper;
  };

  //-- use composables --//
  const route = useRoute();
  const {
    formBuilderInfo
  } = useFormBuilder();
  const variableManager = useVariableManager();
  const accountRecoveryMapper = useAccountRecoveryMapper();
  const {
    databaseOptions
  } = accountRecoveryMapper;

  //-- layouts logic --//
  const layouts = ref([]);
  onMounted(async () => {
    const {
      data: {
        data
      }
    } = await fetchLayoutService(route.params.appId);
    layouts.value = data;
  });

  // Id column types
  const primaryIdColumnTypes = ref([{
    id: 'text-input',
    value: 'Text'
  }, {
    id: 'email-input',
    value: 'Email'
  }]);

  //-- load form logic --//
  // Account recovery module
  const authModules = ref([]);
  const resetPasswordModules = ref([]);

  // new code
  const isFormBuilderModalActive = ref(false);
  const passwordResetLinkVariableRef = '__password_reset_link';
  const defaultResetPasswordEamilBody = `<p>Reset password link <span class=\"mention\" data-mention-id=\"${passwordResetLinkVariableRef}\" data-variable-type=\"variable\" name=\"Account Recovery > ${passwordResetLinkVariableRef}\">@Account Recovery &gt; password_reset_link</span> </p>`;
  const defaultValue = {
    ...generateDefaultForm(),
    name: 'Account Recovery',
    custom_url_name: 'account-recovery',
    sections: [createSection([createTextInput(), createNextButton()])],
    additionalInfo: {
      database: '',
      table: '',
      primaryIdColumnType: 'text-input',
      passwordColumn: '',
      sender: '',
      subject: '',
      emailBody: defaultResetPasswordEamilBody
    }
  };
  const loadInfo = () => {
    formBuilderInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  const createResetPasswordLinkVariable = async () => {
    const {
      appId
    } = route.params;
    const {
      moduleId
    } = moduleStore;
    const variables = [{
      name: 'password_reset_link',
      reference: passwordResetLinkVariableRef
    }];
    await moduleStore.createVariable(appId, moduleId, {
      module_id: moduleId,
      node_id: moduleGraphStore.selectedNodeId,
      node_name: formBuilderInfo.value.name,
      variables
    });
  };
  watch(() => formBuilderInfo.value, () => {
    emit('input', formBuilderInfo.value);
    variableManager.saveVariables();
    createResetPasswordLinkVariable();
  }, {
    deep: true
  });
  return {
    formBuilderInfo,
    accountRecoveryMapper,
    databaseOptions,
    primaryIdColumnTypes,
    authModules,
    resetPasswordModules,
    isFormBuilderModalActive
  };
};
__sfc_main.components = Object.assign({
  ScreenOptions,
  BaseSelect,
  Editor,
  FormBuilderModal
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss">
@import '~@/style/utilities.scss';
</style>
  