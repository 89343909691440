<template>
  <div>
    <BaseHeading
      tag="h6"
      class="has-text-weight-bold"
    >
      {{ props.heading }}
    </BaseHeading>
    <LabelInput
      v-if="basicProperties.hasLabel !== undefined"
      :selected-field-type="props.selectedFieldType"
    />
    <PlaceholderInput
      v-if="basicProperties.hasPlaceholder !== undefined || selectedField.type === 'iban'"
      :selected-field-type="selectedField.type"
    />
    <TileProperties
      v-if="fieldType === 'tile'"
      :selected-field-type="props.selectedFieldType"
    />
    <TileOptions
      v-if="fieldType === 'tile'"
      :selected-field-type="props.selectedFieldType"
    />
    <div
      v-if="basicProperties.hasAutocomplete !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.hasAutocomplete"
      >
        Has autocomplete option
      </b-switch>
    </div>
    <div
      v-if="basicProperties.width !== undefined"
      class="mt-2"
    >
      <BaseSelect
        v-model="basicProperties.width"
        label="Field width"
        expanded
      >
        <option value="is-12">
          Full width
        </option>
        <option value="is-6">
          Half width
        </option>
        <option value="is-4">
          One-third width
        </option>
      </BaseSelect>
    </div>
    <LimitInput
      v-if="basicProperties.hasLimit !== undefined"
      :selected-field-type="props.selectedFieldType"
    />
    <DynamicChoicesInput 
      v-if="basicProperties.hasDynamicChoices !== undefined"
      :selected-field-type="props.selectedFieldType"
    />
    <ChoicesInput
      v-if="basicProperties.choices !== undefined && !basicProperties.hasDynamicChoices"
      :selected-field-type="props.selectedFieldType"
    />
    <div
      v-if="basicProperties.multiselect !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.multiselect"
      >
        Allow a multiple selection
      </b-switch>
    </div>
    <div
      v-if="basicProperties.showControls !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.showControls"
      >
        Show control buttons
      </b-switch>
    </div>
    <div
      v-if="(basicProperties.isDisabled !== undefined || selectedField.type === 'iban') && !moduleGraphStore.isAuthScreen"
      class="mt-2"
    >
      <DynamicValueSwitch
        v-model="basicProperties.isDisabled"
        :is-dynamic="basicProperties.isDisabledDynamic"
        label="Read only"
        @update-dynamic-switch="updateBasicProperty('isDisabledDynamic', $event)"
      />
    </div>
    <DefaultValueInput 
      v-if="(basicProperties.hasDefaultValue !== undefined || selectedField.type === 'iban') && !moduleGraphStore.isAuthScreen"
      :selected-field-type="props.selectedFieldType"
    />
    <TooltipInput 
      v-if="basicProperties.hasInfoTooltip !== undefined"
      :selected-field-type="props.selectedFieldType"
    />
    <AddressProperties
      v-if="fieldType === 'address'"
      :selected-field-type="props.selectedFieldType"
    />
    <FormattingInput
      v-if="fieldType === 'date' || basicProperties.format !== undefined"
      class="mt-2"
      :selected-field-type="props.selectedFieldType"
    />
    <div
      v-if="fieldType === 'number-input'"
      class="mt-2"
    >
      <Editor
        v-model="basicProperties.min"
        label="Minimum Limit"
        :input="true"
        class="mb-2"
      />
      <Editor
        v-model="basicProperties.max"
        label="Maximum Limit"
        :input="true"
      />
    </div>
    <template
      v-if="selectedField.type === 'grid' && props.selectedFieldType !== 'gridCell'"
    >
      <GridProperties />
      <BasicProperties
        v-if="selectedGridCells.length"
        selected-field-type="gridCell"
        heading=""
      />
    </template>

    <div
      v-if="basicProperties.multiple !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.multiple"
      >
        Allow multiple files
      </b-switch>
    </div>

    <div
      v-if="basicProperties.pagination !== undefined"
      class="mt-2"
    >
      <BaseLabel>Pagination</BaseLabel>
      <b-numberinput
        v-model.number="basicProperties.pagination"
        min="0"
        max="100"
      />
    </div>

    <div
      v-if="basicProperties.tableIsFilterable !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.tableIsFilterable"
      >
        Is Filterable
      </b-switch>
    </div>

    <div
      v-if="basicProperties.tableIsSortable !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.tableIsSortable"
      >
        Is Sortable
      </b-switch>
    </div>

    <div
      v-if="basicProperties.tableIsExportable !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.tableIsExportable"
      >
        Is Exportable
      </b-switch>
    </div>
    <div
      v-if="basicProperties.tableIsExportable && fieldType === 'data-table'"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.exportAllColumns"
      >
        Export All Columns
      </b-switch>
    </div>

    <DocumentProperties 
      v-if="basicProperties.document !== undefined"
      :selected-field-type="props.selectedFieldType"
      class="mt-2"
    />
    <TableColumnsInput
      v-if="fieldType === 'data-table'"
    />
    <div
      v-if="basicProperties.rawText !== undefined"
      class="mt-2"
    >
      <Editor
        v-model="basicProperties.rawText"
      />
    </div>
    <div
      v-if="basicProperties.btnText !== undefined"
      class="mt-2"
    >
      <BaseInput
        v-model="basicProperties.btnText"
        expanded
      />
    </div>

    <div
      v-if="basicProperties.clearStateOnSuccess !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.clearStateOnSuccess"
        expanded
      >
        Clear state after reload
      </b-switch>
    </div>

    <div
      v-if="basicProperties.isBlock !== undefined"
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.isBlock"
        expanded
      >
        Button has max width
      </b-switch>
    </div>

    <TariffComparisionProperties 
      v-if="basicProperties.tariffs !== undefined"
    />

    <PaymentGatewayProperties 
      v-if="basicProperties.hasPaymentGateway !== undefined"
      :selected-field-type="props.selectedFieldType"
    />
    <div v-if="['text-input', 'text-area', 'email-input', 'html-editor', 'number-input', 'dropdown', 'radio-box', 'checkbox', 'date', 'file-upload', 'password', 'toc', 'e-signature', 'iban', 'address', 'tariff-comparison'].includes(fieldType)">
      <div class="is-flex is-justify-content-space-between mt-3">
        <BaseLabel
          v-if="basicProperties.isIgnoreHiddenFieldValueDynamic"
          class="mt-2"
        >
          Ignore hidden field value
        </BaseLabel>
        <b-switch
          v-else
          v-model="basicProperties.ignoreHiddenFieldValue"
        >
          Ignore hidden field value
        </b-switch>
        <b-switch
          v-model="basicProperties.isIgnoreHiddenFieldValueDynamic"
        >
          Dynamic
        </b-switch>
      </div>
      <Editor
        v-if="basicProperties.isIgnoreHiddenFieldValueDynamic"
        v-model="basicProperties.ignoreHiddenFieldValue"
        :input="true"
        :formula="true"
      />
    </div>
    <div
      v-if="fieldType === 'date'"
      class="mt-3"
    >
      <DynamicValueSwitch
        v-model="basicProperties.hasTimePicker"
        :is-dynamic="basicProperties.isTimePickerToggleDynamic"
        label="Show time picker"
        @update-dynamic-switch="updateBasicProperty('isTimePickerToggleDynamic', $event)"
      />
    </div>
    <div
      v-if="fieldType === 'password'"
      class="mt-3"
    >
      <DynamicValueSwitch
        v-model="basicProperties.isPasswordVisible"
        :is-dynamic="basicProperties.isPasswordVisibleDynamic"
        label="Show password"
        @update-dynamic-switch="updateBasicProperty('isPasswordVisibleDynamic', $event)"
      />
    </div>
    <div
      v-if="fieldType === 'time'"
      class="mt-3"
    >
      <DynamicValueSwitch
        v-model="basicProperties.is24HourFormat"
        :is-dynamic="basicProperties.is24HourFormatDynamic"
        label="24 hour format"
        @update-dynamic-switch="updateBasicProperty('is24HourFormatDynamic', $event)"
      />
    </div>
    <div
      v-if="fieldType === 'iban'"
      class="mt-3"
    >
      <DynamicValueSwitch
        v-model="basicProperties.hasIbanApiValidation"
        :is-dynamic="basicProperties.isIbanApiValidationToggleDynamic"
        label="Validate IBAN using api"
        @update-dynamic-switch="updateBasicProperty('isIbanApiValidationToggleDynamic', $event)"
      />
    </div>

    <div
      v-if="['checkbox','radio-box'].includes(fieldType)"
      class="mt-3"
    >
      <b-switch
        v-model="basicProperties.displayOptionsInRow"
      >
        Display Options in Row
      </b-switch>
    </div>
    <div
      v-if="fieldType === 'dropdown'"
      class="mt-3"
    >
      <b-switch
        v-model="basicProperties.sortOptionsInAlphabeticOrder"
      >
        Sort options in alphabetic order
      </b-switch>
    </div>
    <div v-if="fieldType === 'file-upload'">
      <b-switch
        v-model="basicProperties.hasCustomFileName"
      >
        Has custom file name
      </b-switch>
      <Editor
        v-if="basicProperties.hasCustomFileName"
        v-model="basicProperties.customFileName"
        label="Custom file name"
        :input="true"
        :formula="true"
      />
    </div>
    <div
      v-if="fieldType === 'iframe'"
      class="mt-2"
    >
      <Editor
        v-model="basicProperties.url"
        label="URL"
        :input="true"
      />
    </div>
  </div>
</template>

<script >
// libs
import { defineAsyncComponent, set, computed } from '@vue/composition-api';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import { ChoicesInput, DefaultValueInput, DynamicChoicesInput, FormattingInput, LabelInput, LimitInput, PlaceholderInput, TableColumnsInput, TooltipInput, TariffComparisionProperties, DocumentProperties, PaymentGatewayProperties, GridProperties, TileOptions, TileProperties } from './components/basicPropertiesComponents';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import BaseHeading from '@/modules/core/components/generics/BaseHeading.vue';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
import AddressProperties from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/components/address/AddressProperties.vue';
// composables
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useGrid } from './components/grid/grid';
// Stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
const BasicProperties = defineAsyncComponent(() => import('./BasicProperties.vue'));
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  },
  heading: {
    type: String,
    default: 'Basic:'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    selectedGridCells
  } = useGrid();
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const {
    selectedField
  } = useFormBuilder();
  const moduleGraphStore = useModuleGraphStore();
  const gridCellType = computed(() => {
    const currentGridCell = selectedGridCells.value?.[0];
    return props.selectedFieldType === 'gridCell' ? currentGridCell?.data?.properties?.basic?.element?.type : null;
  });
  const fieldType = computed(() => gridCellType.value || selectedField.value.type);
  const updateBasicProperty = (key, value) => {
    if (key in basicProperties.value) {
      basicProperties.value[key] = value;
    } else {
      set(basicProperties.value, key, value);
    }
  };
  return {
    props,
    selectedGridCells,
    basicProperties,
    selectedField,
    moduleGraphStore,
    fieldType,
    updateBasicProperty
  };
};
__sfc_main.components = Object.assign({
  BaseHeading,
  LabelInput,
  PlaceholderInput,
  TileProperties,
  TileOptions,
  BaseSelect,
  LimitInput,
  DynamicChoicesInput,
  ChoicesInput,
  DynamicValueSwitch,
  DefaultValueInput,
  TooltipInput,
  AddressProperties,
  FormattingInput,
  Editor,
  GridProperties,
  BasicProperties,
  BaseLabel,
  DocumentProperties,
  TableColumnsInput,
  BaseInput,
  TariffComparisionProperties,
  PaymentGatewayProperties
}, __sfc_main.components);
export default __sfc_main;
</script>
