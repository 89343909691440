<template>
  <div>
    <BaseHeading
      tag="h6"
      class="mt-3 mb-1 has-text-weight-bold"
    >
      {{ props.heading }}
    </BaseHeading>
    <div>
      <InvalidValueInput
        v-if="validationProperties.message !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <RequiredFieldInput
        v-if="validationProperties.isRequired !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <RegexInput 
        v-if="fieldType === 'iban' || validationProperties.hasRegex !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <DateRestrictionInput 
        v-if="validationProperties.hasRestriction !== undefined"
        :selected-field-type="selectedFieldType"
      />
      <FileUploadProperties
        v-if="fieldType === 'file-upload'"
        :selected-field-type="selectedFieldType"
      />
      <RangeErrorInput
        v-if="fieldType === 'number-input'"
        :selected-field-type="selectedFieldType"
      />
      <AddressProperties 
        v-if="fieldType === 'address'"
        :selected-field-type="selectedFieldType"
      />
    </div>
  </div>
</template>

<script >
// libs
import { computed } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
// components
import { RequiredFieldInput, RegexInput, DateRestrictionInput } from './components/validationComponentsIndex';
import BaseHeading from '@/modules/core/components/generics/BaseHeading.vue';
import RangeErrorInput from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/components/RangeErrorInput.vue';
import FileUploadProperties from './components/FileUploadProperties.vue';
import InvalidValueInput from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/components/InvalidValueInput.vue';
import AddressProperties from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/components/address/AddressProperties.vue';
// composables
import { useValidationProperties } from './validationProperties';
import { useGrid } from '../basic-properties/components/grid/grid';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
// misc
import { createInvalidValueMessage } from './validationPropertyUtils';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  },
  heading: {
    type: String,
    default: 'Validation:'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);
  const {
    selectedGridCells
  } = useGrid();
  const {
    selectedField
  } = useFormBuilder();
  const gridCellType = computed(() => {
    const currentGridCell = selectedGridCells.value?.[0];
    return props.selectedFieldType === 'gridCell' ? currentGridCell?.data?.properties?.basic?.element?.type : null;
  });
  const fieldType = computed(() => gridCellType.value || props.selectedFieldType);
  const handleMigratingOldFields = () => {
    if (selectedField.value.type === 'iban' && Object.keys(validationProperties.value).length && validationProperties.value.message === undefined) {
      validationProperties.value = {
        ...validationProperties.value,
        ...createInvalidValueMessage()
      };
    }
  };
  debouncedWatch(() => selectedField.value, () => {
    handleMigratingOldFields();
  }, {
    immediate: true,
    debounce: 700
  });
  return {
    props,
    validationProperties,
    fieldType
  };
};
__sfc_main.components = Object.assign({
  BaseHeading,
  InvalidValueInput,
  RequiredFieldInput,
  RegexInput,
  DateRestrictionInput,
  FileUploadProperties,
  RangeErrorInput,
  AddressProperties
}, __sfc_main.components);
export default __sfc_main;
</script>
