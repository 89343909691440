export const createRequired = (isRequiredMessage = 'This field is required.', isRequired = true, isRequiredMessageDynamic = false, isRequiredDynamic = false) => ({
    isRequired,
    isRequiredMessage,
    isRequiredMessageDynamic,
    isRequiredDynamic,
});

export const createRegex = (hasRegexMessage = 'This value is not allowed.', regex = '', hasRegex = false, isRegexMessageDynamic = false) => ({
    hasRegex,
    hasRegexMessage,
    regex,
    isRegexMessageDynamic
});

export const createInvalidValueMessage = (message = 'Value is not valid', isMessageDynamic = false) => ({
    message,
    isMessageDynamic
});

export const createDisabled = (isDisabled = false, isDisabledDynamic = false) => ({
    isDisabled,
    isDisabledDynamic
});

export const createAddressSubFieldsValidationProps = () => ({
    street: {},
    number: {},
    zipCode: {
        regex: '',
        regexErrorMessage: ''
    },
    city: {},
    country: {},
    state: {},
    district: {}
});
