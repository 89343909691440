<template>
  <div v-if="validationProperties.subFieldsProperties">
    <b-collapse
      class="card"
      animation="slide"
      aria-id="contentIdForA11y3"
    >
      <template #trigger="slotProps">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
          :aria-expanded="slotProps.open"
        >
          <p class="card-header-title">
            {{ subField.label }}
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="slotProps.open ? 'menu-down' : 'menu-up'"
            />
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <BaseInput
            v-model="validationProperties.subFieldsProperties[subField.key].regex"
            label="Regex"
            expanded
          />
          <Editor
            v-model="validationProperties.subFieldsProperties[subField.key].regexErrorMessage"
            label="Regex Error Message"
            :input="true"
          />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script >
import { useValidationProperties } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationProperties';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  },
  subField: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);
  return {
    validationProperties
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
