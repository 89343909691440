<template>
  <div>
    <div v-if="validationProperties.subFieldsProperties">
      <BaseLabel variant="bold">
        Sub Fields:
      </BaseLabel>
      <AddressSubFieldsProperties
        :sub-field="{ label: 'Zip Code', key: 'zipCode' }"
      />
    </div>
  </div>
</template>

<script >
// libs
import { set } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
// components
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import AddressSubFieldsProperties from './AddressSubFieldsProperties.vue';
// composables
import { useValidationProperties } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationProperties';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
// misc
import { createAddressSubFieldsValidationProps } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationPropertyUtils';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);
  const {
    selectedField
  } = useFormBuilder();
  const handleMigratingOldFields = () => {
    if (selectedField.value.type === 'address' && Object.keys(validationProperties.value).length && validationProperties.value.subFieldsProperties === undefined) {
      set(validationProperties.value, 'subFieldsProperties', createAddressSubFieldsValidationProps());
    }
  };
  debouncedWatch(() => selectedField.value, () => {
    handleMigratingOldFields();
  }, {
    immediate: true,
    debounce: 700
  });
  return {
    validationProperties
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  AddressSubFieldsProperties
}, __sfc_main.components);
export default __sfc_main;
</script>
